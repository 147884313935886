<template lang="pug">
  .cd-actions

    .cd-actions__toggle
      v-btn(color="primary" @click="toggle" outlined small) Bulk Actions

    .cd-actions__container(:class="{opened: showing}")
      candidates-service
        markAsProcessedBulk(:activeItems="activeItems" @clearSelection="$emit('clear:selection')").cd-actions-item

      candidates-service
        tryToReserve(:activeItems="activeItems" :disabled="!hasActiveItems").cd-actions-item

      candidates-service
        emailBulk(:activeItems="activeItems").cd-actions-item

      handleFailedCandidatesProvider(v-slot="handleFailed")
        v-dialog(:value="handleFailed.showing" @input="handleFailed.toggle" scrollable)
          handleFailedBody(
            :list="handleFailed.failedCandidates"
            :error="handleFailed.error"
            :totalSendCount="handleFailed.totalSendCount"
            @input="handleFailed.toggle"
          )

      handleFailedCandidatesProvider(v-slot="handleFailed")
        quickReserve(
          :activeItems="activeItems"
          :disabled="!hasActiveItems"
          :city="activeItem ? activeItem.city : -1"
          @create="quickReserveHandler(handleFailed.check, $event)"
          title="Quick Exchange"
          isExchange
        ).cd-actions-item

      handleFailedCandidatesProvider(v-slot="handleFailed")
        exchange(
          :disabled="!hasActiveItems"
          :activeItems="activeItems"
          @send="handleFailed.check($event, activeItems.length)"
          title="Suggest exchange"
          isExchange
        ).cd-actions-item

      candidates-service
        bulkAssignLabels(:disabled="!hasActiveItems" :activeItems="activeItems").cd-actions-item

      candidates-service
        bulkAssignLabels(btnText="Unassign labels" mode="remove" :disabled="!hasActiveItems" :activeItems="activeItems").cd-actions-item

      candidates-service
        addRequests(
          exchange
          title="Add exchange exam requests"
          :disabled="!hasActiveItems"
          :activeItems="activeItems"
          @send="handleFailed.check($event, activeItems.length)"
        ).cd-actions-item

      sync(:activeItems="activeItems" :disabled="!hasActiveItems").cd-actions-item

      bulkTransportConfig(
        :disabled="!hasActiveItems"
        :activeItems="activeItems"
        :courseDayID="activeCourseDay"
      ).cd-actions-item

      assignCandidatesToJourney(
        :course="activeItem"
        :activeItems="activeItems"
        :disabled="!hasActiveItems"
        :courseDayID="activeCourseDay"
        @updated="$emit('needToUpdate', true)"
      )
        template(v-slot:activator="createJourney")
          v-btn(
            small
            @click="createJourney.open"
            color='primary'
            :disabled="!hasActiveItems"
            outlined
          ).cd-actions-item Change journey
      candidatesZoomRegistrant(
        v-if="featureFlags[FEATURE_FLAG.NCRM_3360] && hasZoomWebinarID"
        :activeItems="activeItems"
        :courseID="activeCourseDay"
        :disabled="!hasActiveItems"
      ).cd-actions-item

      candidatesZoomAllRegistrant(
        v-if="featureFlags[FEATURE_FLAG.NCRM_3360] && hasZoomWebinarID"
        :courseID="activeCourseDay"
      ).cd-actions-item

      candidatesSetZoomPresentie(
        v-if="featureFlags[FEATURE_FLAG.NCRM_3360] && hasZoomWebinarID"
        :courseID="activeCourseDay"
      ).cd-actions-item

      candidatesSendZoomInvite(
        v-if="featureFlags[FEATURE_FLAG.NCRM_3360] && hasZoomWebinarID"
        :activeItems="activeItems"
        :courseID="activeCourseDay"
        :disabled="!hasActiveItems"
      ).cd-actions-item


      // bulk upload candidates photo
      courseGroupPhoto(
        v-if="featureFlags[FEATURE_FLAG.NCRM_6506]"
        :course="activeItem"
        :activeItems="activeItems"
        :courseID="activeCourseDay"
        :disabled="!hasActiveItems"
      ).cd-actions-item

      // download&upload course photo
      coursePhoto(
        v-if="featureFlags[FEATURE_FLAG.NCRM_6506]"
        :courseID="activeCourseDay"
        :coursePhoto="getCoursePhoto"
        :hasActiveItems="hasActiveItems"
        :disabled="!this.$route.query.course_day"
      ).cd-actions-item
      download-photo(
        :activeItems="activeItems"
        :courseID="activeCourseDay"
        :coursePhoto="getCoursePhoto"
        :hasActiveItems="hasActiveItems"
        :list="list"
      ).cd-actions-item
      copy(
        :activeItems="activeItems"
        :groupedList="groupedList"
        :disabled="!hasActiveItems"
        :list="list"
        multiple
      ).cd-actions-item

      candidates-service
        candidateColor(:activeItems="activeItems" :disabled="!hasActiveItems").cd-actions-item
</template>

<script>
import featureFlagsMixin from '@/mixins/featureFlags.mixin'
import showingMixin from '@/mixins/showing.mixin'

export default {
  name: 'CourseDayActions',

  mixins: [featureFlagsMixin, showingMixin],

  props: {
    activeItems: Array,
    activeItem: Object,
    groupedList: Object,
    list: Array
  },

  computed: {
    hasActiveItems() {
      return !!(this.activeItems && this.activeItems.length)
    },

    activeCourseDay() {
      return +this.$route.query.course_day
    },

    hasZoomWebinarID() {
      return !!(this.activeItem && this.activeItem.zoom_webinar_id)
    },

    getCoursePhoto() {
      if (this.activeItem && this.activeItem.course_photo) return this.activeItem.course_photo
      else return null
    }
  },

  methods: {
    quickReserveHandler(callback, res) {
      callback(res, this.activeItems.length)
      this.$emit('update')
    }
  },

  components: {
    candidatesService: () => import('@/app/admin/modules/candidates/components/CandidatesProvider.vue'),
    handleFailedCandidatesProvider: () => import('@/app/admin/modules/candidates/components/CandidatesHandleFailedProvider'),
    bulkAssignLabels: () => import('@/app/admin/modules/candidates/components/candidates/CandidatesBulkAssignLabels.vue'),
    copy: () => import('@/app/admin/modules/candidates/components/candidates/CandidatesCopy.vue'),
    downloadPhoto: () => import('@/app/admin/modules/candidates/components/candidates/CandidatesPhotoActions.vue'),
    addRequests: () => import('@/app/admin/modules/candidates/components/CandidatesAddExamRequest.vue'),
    markAsProcessedBulk: () => import('../../course_managment/components/CMMarkAsProceedBulk.vue'),
    handleFailedBody: () => import('@/app/admin/modules/candidates/components/CandidatesHandleFailedBody.vue'),
    quickReserve: () => import('../../course_managment/components/CMQuickReserve.vue'),
    tryToReserve: () => import('@/app/admin/modules/candidates/components/CandidatesTryToReserve.vue'),
    exchange: () => import('@/app/admin/modules/candidates/components/CandidatesExchange.vue'),
    sync: () => import('@/app/admin/modules/candidates/components/CandidatesSync.vue'),
    candidateColor: () => import('@/app/admin/modules/candidates/components/CandidateColor.vue'),
    emailBulk: () => import('@/app/admin/modules/candidates/components/CandidatesEmail.vue'),
    coursePhoto: () => import('@/app/admin/modules/course_day/components/actions/CoursePhotoUpload.vue'),
    courseGroupPhoto: () => import('@/app/admin/modules/course_day/components/actions/CourseGroupPhoto.vue'),
    bulkTransportConfig: () => import('../components/CourseDayBulkTranportConfig.vue'),
    // zoom
    assignCandidatesToJourney: () => import('@/app/admin/modules/course_day/components/journey/JourneyAssignCandidates.vue'),
    candidatesZoomRegistrant: () => import('@/app/admin/modules/course_day/components/zoom/CandidatesZoomRegistrant.vue'),
    candidatesZoomAllRegistrant: () => import('@/app/admin/modules/course_day/components/zoom/CandidatesZoomAllRegistrant.vue'),
    candidatesSetZoomPresentie: () => import('@/app/admin/modules/course_day/components/zoom/CandidatesSetZoomPresentie.vue'),
    candidatesSendZoomInvite: () => import('@/app/admin/modules/course_day/components/zoom/CandidatesSendZoomInvite.vue')
  }
}
</script>

<style lang="scss">
.cd-actions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px;
  border-bottom: 1px solid $border-color;

  &__container {
    display: flex;
    flex-wrap: wrap;

    &.opened {
      display: flex;
    }

    @include media("<=tablet") {
      display: none;
      width: 100%;
      align-items: start;
      flex-direction: row;
      padding-top: 15px;
    }
  }

  &__toggle {
    width: 100%;
    flex-direction: column;
    display: none;

    @include media("<=tablet") {
      display: flex;
    }
  }

  &-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 4px;
    margin-right: 8px;
  }
}
</style>